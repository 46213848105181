import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { ChevronDown } from 'lucide-react'
import { ThemeContext } from '../context/ThemeContext'
import Layout from '../components/Layout'
import Section from '../components/Section'
import Hero from '../components/Hero'
import Link from '../components/Link'
import Video from '../components/Video'
import {
  base,
  Text,
  // Excerpt,
  H1,
  // H2,
  H4,
  H6,
} from '../components/Text'
import { Grid, Column } from '../components/Grid'
import Cover from '../components/Cover'
// import Icon from '../components/Icon'
import Image from '../components/Image'
import getThemeColor from '../lib/getThemeColor'
// import EmbedPlayer from '../components/EmbedPlayer'
import Tags, { tagsWrapperModifiers } from '../components/Tags'
import ContentWrapper from '../components/ContentWrapper'
import { fluidRange, breakpoints, breakpointNr } from '../style'
import getMetaFromPost from '../lib/getMetaFromPost'
import RichText from '../components/RichTextContentful'
import { formatPhone } from '../lib/format'
import { isDefaultLanguage } from '../components/SelectLanguage'

const StyledH1 = styled(H1)`
  margin-bottom: 0.175em;
  font-size: 2em;
  font-weight: 400;
  line-height: 1.1em;

  ${base}
  color: ${props => props.textColor};

  @media ${breakpoints.medium} {
    font-size: 3em;
    letter-spacing: -0.05em;
  }

  @media ${breakpoints.large} {
    font-size: ${fluidRange({
      min: 45,
      max: 92,
      viewportMin: breakpointNr.large,
      viewportMax: breakpointNr.xlarge,
    })};
  }

  @media ${breakpoints.xlarge} {
    font-size: 5em;
  }
`

const ContactText = styled(Text)`
  text-align: ${props => props.textAlign ?? 'right'};
  > span {
    display: inline-block;
    margin-bottom: 12px;
  }

  ${tagsWrapperModifiers}

  @media ${breakpoints.medium} {
    text-align: ${props => props.textAlign ?? 'right'};
  }
`

const TechDescription = styled(Text)`
  font-size: 0.75rem;
  color: ${props => props.textColor};

  @media ${breakpoints.medium} {
    font-size: 1rem;
  }
`
const SVGWrapper = styled('div')`
  & svg {
    width: 80px;
    & path {
      fill: ${props => props.textColor};
    }
  }
`

export default function Case({ data, pageContext: { next }, location }) {
  const {
    title,
    // excerpt,
    tags,
    solutions,
    client,
    contact,
    featuredVideo = false,
    featuredImage,
    isVideoURL = false,
    body,
    themeStyle,
    heroOverlay,
  } = data.contentfulCase

  const themeMode = themeStyle?.[0] ?? 'Default'
  const theme = React.useContext(ThemeContext)
  const themeColor = getThemeColor(themeMode)

  return (
    <Layout meta={getMetaFromPost(data.contentfulCase)} location={location}>
      <section>
        {(featuredImage || featuredVideo) && (
          <Cover
            bg={theme.background}
            isVideo={!!featuredVideo}
            heroOverlay={heroOverlay}
          >
            {featuredImage && !featuredVideo && (
              <Image fluid={featuredImage.fluid} alt="" />
            )}

            {/* {featuredVideo && (
              <EmbedPlayer src={featuredVideo.file.url} bg="transparent" />
            )} */}

            {featuredVideo && featuredVideo.file && !isVideoURL && (
              <>
                {/* <video playsInline loop controls autoPlay muted>
                  <source src={featuredVideo.file.url} type="video/mp4" />
                  <p>
                    Video could not be found.{' '}
                    <a href="mailto:info@d13.studio">Please let me know</a>
                  </p>
                </video> */}

                <Video
                  src={featuredVideo.file.url}
                  bg="transparent"
                  style={{
                    position: 'static',
                  }}
                />
                {/* <div className="c-dZVneL c-dZVneL-cDDHAO-controlsPosition-bottom-right">
                  <span className="c-jNQDOs c-jNQDOs-kmolIJ-isLoading-false" />

                  <button
                    aria-label="pause"
                    className="c-cSdWWG c-cSdWWG-feNIYJ-isLoading-false c-cSdWWG-jCtSHI-isPlaying-true"
                  >
                    <span className="c-fQMEQT">
                      <svg
                        width="9"
                        height="12"
                        viewBox="0 0 9 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.18258 0.252136C0.815414 0.252136 0.518311 0.549564 0.518311 0.916402V11.0836C0.518311 11.3503 0.675295 11.5803 0.902339 11.6861C0.987967 11.7257 1.08268 11.7479 1.18258 11.7479H1.60942C1.97658 11.7479 2.27369 11.4504 2.27369 11.0836V0.916402C2.27369 0.549564 1.97658 0.252136 1.60942 0.252136H1.18258Z"
                          fill="white"
                        />
                        <path
                          d="M6.82884 0.560997C6.76397 0.663735 6.72634 0.78569 6.72634 0.916402V11.0836C6.72634 11.3154 6.8457 11.5195 7.02474 11.6384C7.12983 11.7076 7.25568 11.7479 7.39061 11.7479H7.81745C8.18462 11.7479 8.48172 11.4504 8.48172 11.0836V0.916402C8.48172 0.549564 8.18462 0.252136 7.81745 0.252136H7.39061C7.15448 0.252136 6.9469 0.375389 6.82884 0.560997Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    <span className="c-czopae">
                      <svg
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.408 5.5458C12.7182 5.74208 12.7182 6.19455 12.408 6.39083L3.76735 11.8583C3.43443 12.069 3 11.8298 3 11.4358L3 0.500807C3 0.10684 3.43443 -0.132369 3.76735 0.0782889L12.408 5.5458Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </button>
                </div> */}
              </>
            )}

            <Hero
              pt={[20, 7]}
              pb={[5, 7]}
              scrollButtonElement="#content"
              keepContentMargin
              alignment="center"
              fullWidth
              position="absolute"
              rowGap={40}
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              {client && (
                <H4
                  mb="2"
                  textColor={
                    // eslint-disable-next-line no-nested-ternary
                    themeMode === 'Default' ? theme.color : themeColor
                  }
                >
                  {client.name}
                </H4>
              )}
              <StyledH1
                mb="5"
                textColor={
                  // eslint-disable-next-line no-nested-ternary
                  themeMode === 'Default' ? theme.color : themeColor
                }
              >
                {title}
              </StyledH1>
              {tags && (
                <Tags
                  alignItems="center"
                  justifyContent="center"
                  items={tags}
                  textColor={
                    // eslint-disable-next-line no-nested-ternary
                    themeMode === 'Default' ? theme.color : themeColor
                  }
                  variant="small"
                  columnGap={20}
                />
              )}
            </Hero>
          </Cover>
        )}

        <Section id="content" pt={[5, 7]} pb={[10, 13]}>
          {body && (
            <ContentWrapper>
              <Grid>
                <RichText document={body.json} />
              </Grid>
            </ContentWrapper>
          )}
        </Section>

        <Grid
          justifyContent="space-between"
          alignItems="flex-start"
          pt={[2, 2]}
          width={7.5}
        >
          {solutions && (
            <Column sm={12} alignItems="center" justifyContent="center">
              {solutions.map(solution => (
                <Grid
                  key={solution.name}
                  border
                  borderColor={theme.color}
                  pt={[2, 2]}
                  pb={[2, 2]}
                >
                  <Column sm={12} gutter="none">
                    <TechDescription textColor={theme.color} mb="2">
                      {solution.displayText}
                    </TechDescription>
                  </Column>
                  <Column sm={6}>
                    {solution?.icon?.file &&
                      solution?.icon?.file.contentType === 'image/svg+xml' && (
                        <SVGWrapper
                          textColor={theme.color}
                          dangerouslySetInnerHTML={{
                            __html: solution?.icon.svg.content,
                          }}
                        />
                      )}
                  </Column>
                  <Column sm={6} m="0">
                    <TechDescription textColor={theme.color}>
                      {solution.description}
                    </TechDescription>
                  </Column>
                </Grid>
              ))}
            </Column>
          )}

          {contact && (
            <Column sm={12} mt="12">
              <ContactText textAlign="center" textColor={theme.color}>
                {isDefaultLanguage() ? 'Your cup of tea?' : 'Vill du veta mer?'}

                <br />
                <Link
                  textColor={theme.color}
                  styleVariant={theme.theme}
                  href={`mailto:${contact.email}`}
                >
                  {contact.email}
                </Link>
                <br />
                <Link
                  textColor={theme.color}
                  styleVariant={theme.theme}
                  href={`tel:${formatPhone(contact.phone)}`}
                >
                  {contact.phone}
                </Link>
              </ContactText>
            </Column>
          )}
        </Grid>
        {next && (
          <Section as="footer" bg={theme.background} py={[7, 7]}>
            <Grid>
              <Column>
                <H6 textColor={theme.color} mb={[3, 7]}>
                  Next case
                  <ChevronDown color="white" />
                </H6>
                <H1 as="div">
                  <Link
                    as={Link}
                    to={next.fields.slug}
                    rel="next"
                    textColor={theme.color}
                  >
                    {next.frontmatter.client}
                  </Link>
                </H1>
              </Column>
            </Grid>
          </Section>
        )}
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!, $locale: String!) {
    contentfulCase: contentfulCases(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      id
      title
      slug
      themeStyle
      heroOverlay
      featuredVideo {
        file {
          contentType
          url
        }
      }
      featuredImage {
        fluid(quality: 80, maxWidth: 3000) {
          ...GatsbyContentfulFluid
        }
        fixed: resize(width: 1200, height: 630, quality: 80) {
          src
        }
      }
      tags {
        ... on ContentfulTags {
          id
          name
        }
      }
      solutions {
        name
        displayText
        description
        link
        icon {
          file {
            contentType
          }
          svg {
            content
            originalContent
            dataURI
            absolutePath
            relativePath
          }
        }
      }
      excerpt {
        excerpt
      }
      client {
        name
      }
      contact {
        phone
        title
        # firstName
        # lastName
        email
      }
      body {
        json
      }
      seoTitle
      seoDescription {
        seoDescription
      }
      seoImage {
        og: resize(width: 1200, height: 630, quality: 80) {
          src
        }
      }
    }
  }
`
